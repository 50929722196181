<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-form ref="form" :model="formData" label-width="100px">
      <el-form-item label="品牌" prop="brand_id" :rules="rules">
        <!-- <el-select style="width: 400px" size="small" v-model="formData.brand_id" placeholder="请选择">
          <el-option v-for="item in brand_list" :key="item.id" :label="item.name" :value="item.id"> </el-option>
        </el-select> -->
        <SelectBrand v-model="formData.brand_id"></SelectBrand>
      </el-form-item>
      <el-form-item label="车型" prop="name" :rules="rules">
        <el-input style="width: 400px" size="small" v-model="formData.name"></el-input>
      </el-form-item>

      <el-form-item label="描述">
        <el-input style="width: 400px" rows="4" type="textarea" size="small" v-model="formData.description"></el-input>
      </el-form-item>
      <el-form-item label="">
        <el-button type="primary" size="small" @click="save">保存</el-button>
        <el-button type="" size="small" @click="$router.back()">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getDetailAPI, addAPI, editAPI, getBrandListAPI } from './api'
import SelectBrand from '@/views/components/select-brand.vue'
export default {
  name: 'AddOrEdit',
  components: { SelectBrand },
  data() {
    return {
      rules: [{ required: true, message: '该项不能为空', trigger: 'blur' }],
      formData: {
        brand_id: '',
        name: '',
        description: ''
      },
      brand_list: []
    }
  },

  mounted() {
    this.getDictList()
    if (this.$route.params.id) this.getDetail()
  },

  methods: {
    save() {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.formData.id) {
            editAPI(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.$router.back()
            })
          } else {
            addAPI(this.formData).then(() => {
              this.$message.success('添加成功')
              this.$router.back()
            })
          }
        }
      })
    },
    async getDetail() {
      this.formData = await getDetailAPI(this.$route.params.id)
    },
    async getDictList() {
      const { data } = await getBrandListAPI()
      this.brand_list = data || []
    }
  }
}
</script>

<style lang="scss" scoped></style>
